import { graphql, Link, StaticQuery } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import SwiperCore, { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import ErrorIcon from "../../assets/images/signup-error.svg"
import Block from "../../components/common/Block/Block"
import Buttons from "../../components/common/button"
import CTA from "../../components/common/CTA"
import Frame from "../../components/common/frame"
import ImagePointer from "../../components/common/ImagePointer/ImagePointer"
import MarqueeImages from "../../components/common/MarqueeImages/MarqueeImages"
import PopupSignup from "../../components/common/popup-sign-up"
import SignupForm from "../../components/common/sign-up-form"
import SVGIcon from "../../components/common/SVGIcon"
import TestimonialV6 from "../../components/common/testimonial-component/multiple-6"
import VerticalTabs from "../../components/common/VerticalTabComponent/VerticalTabs"
import Navigation from "../../components/navigation"
import Slide from "../../components/utility/slide_content"
import { serverRegions } from "../../constants/globalMessages"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pages/random-lp-page.scss"
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 0.78,
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 767, min: 500 },
    items: 1,
  },
  mobile1: {
    breakpoint: { max: 500, min: 0 },
    items: 1,
  },
}
SwiperCore.use([Autoplay])

const LpPage = ({ pageContext }) => {
  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])
  const { template } = pageContext
  const [popupVisibility, setPopupVisibility] = useState(false)
  const [swiper, setSwiper] = useState()

  const [displayError, setDisplayError] = useState(false)
  const [errorText, setErrorText] = useState("")
  const [serverCountry, setServerCountry] = useState("US")
  const [countryPopup, setCountryPopup] = useState(false)
  const wrapperRef = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(1)
  const totalSlides = pageContext.template.components.filter(
    component => component.__typename === "SuperOps_Testimonial"
  )[0].testimonialDatas.length

  const swiperRef = useRef(null)

  const goToSlide = index => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(index + 1)
      setCurrentIndex(index)
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setCountryPopup(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }

  return (
    <StaticQuery
      query={graphql`
        query lpPage {
          SuperOps {
            links(where: { section: "Footer Security" }) {
              linkName
              slug
            }
          }
        }
      `}
      render={data => (
        <div className="random-lp-page">
          <>
            <Frame
              seo={template.seo}
              ogType="website"
              IsFooterVisble="No"
              newButton
            >
              <div>
                <PopupSignup
                  visibility={popupVisibility}
                  togglePopup={togglePopup}
                  ctaText={template.signupFormCtaText}
                  title={template.signupFormTitle}
                  disallowCommonDomains
                />
              </div>
              <header>
                <Navigation
                  color="#f6f0e6"
                  page="Home"
                  primaryButtonText="GET STARTED FOR FREE"
                  newButton
                  signupPopup
                  disallowCommonDomains
                />
              </header>
              <main>
                {/* {console.log("templates", template)} */}

                {template.components.map(field => {
                  switch (field.__typename) {
                    case "SuperOps_Hero": {
                      return (
                        <section className="hero">
                          <Container className="Layout-container">
                            <Row className="d-flex flex-wrap align-items-center justify-content-between row-gap-48">
                              <Col lg={field.backgroundImage ? 5 : 6}>
                                {field.tag && (
                                  <p className="tag fw-600 p12 text-pink down-to-up-1">
                                    {field.tag}
                                  </p>
                                )}

                                {field.secondaryTag && (
                                  <h1 className="font-roboto normal-heading fw-bold text-deep-purple mb24 down-to-up-1">
                                    {field.secondaryTag}
                                  </h1>
                                )}

                                {field.heroheading && (
                                  <h1
                                    className={`font-roboto mb30 down-to-up-1 linear-bg ${
                                      field.headingLine1 ? "" : "h60"
                                    }`}
                                  >
                                    {field.heroheading.text}
                                  </h1>
                                )}

                                {field.points?.length > 0 && (
                                  <div className="mixed-heading down-to-up-1">
                                    {field.points.map((pt, ind) => (
                                      <h1
                                        key={ind}
                                        className={`font-roboto fw-bold d-line m-0 ${
                                          ind === 1 ? "linear-bg" : "normal"
                                        }`}
                                      >
                                        {pt}
                                      </h1>
                                    ))}
                                  </div>
                                )}

                                {field.heroExcerpt && (
                                  <>
                                    <p
                                      className={`hero-description fw-600 down-to-up-2 ${
                                        field.headingLine1 == "noLine"
                                          ? "font-roboto"
                                          : ""
                                      }`}
                                    >
                                      {field.heroExcerpt}
                                    </p>
                                    {field.headingLine1 !== "noLine" && (
                                      <div className="line down-to-up-3" />
                                    )}
                                  </>
                                )}

                                {field.animAltText?.length > 0 && (
                                  <div className="down-to-up-3 hero-content mb40">
                                    <p
                                      className={`fw-500 d-inline ${
                                        field.headingLine1 ? "p16" : "p18"
                                      }`}
                                    >
                                      {field.animAltText[0]}
                                    </p>
                                    {field.animAltText[1] && (
                                      <p className="fw-500 p18 d-inline text-pink">
                                        {field.animAltText[1]}
                                      </p>
                                    )}
                                  </div>
                                )}

                                {field.lists?.length > 0 && (
                                  <div
                                    className={`lists-parent d-flex  down-to-up-4 flex-wrap ${
                                      field.headingLine2
                                        ? "mt40 gap-30"
                                        : "mt60 justify-content-between"
                                    }`}
                                  >
                                    {field.lists.map((el, idx) => (
                                      <div
                                        key={idx}
                                        className="lists d-flex align-items-center gap-8"
                                      >
                                        <SVGIcon
                                          name="greenTick"
                                          className="tick-logo"
                                        />
                                        <div
                                          className={
                                            field.headingLine2 ? "p16" : "p24"
                                          }
                                        >
                                          <p className="name m-0 fw-600">
                                            {el}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}

                                {field.images?.length > 0 && (
                                  <div className="hero-images down-to-up-4 d-flex">
                                    {field.images.map((img, ind) => (
                                      <div key={ind} className="img-wrapper">
                                        <img
                                          src={img.url}
                                          className="w-100"
                                          alt="badges"
                                        />
                                      </div>
                                    ))}
                                  </div>
                                )}

                                {field.primaryButtonText && (
                                  <Buttons
                                    theme="primary-new"
                                    text={field.primaryButtonText}
                                    onClick={() => togglePopup()}
                                  />
                                )}
                              </Col>

                              {field.backgroundImage ? (
                                <Col lg={7}>
                                  <img
                                    className="w-100 mt10"
                                    alt="hero-img"
                                    src={field.backgroundImage.url}
                                  />
                                </Col>
                              ) : (
                                <Col
                                  lg={6}
                                  className="br-10 signup cn-cta dc-signup"
                                >
                                  <p className="fw-800 font-roboto form-title text-center">
                                    {field.heroCtaText}
                                  </p>
                                  <div className="benefit-parent d-flex">
                                    {field.heroExcerptList.map((el, idx) => (
                                      <div
                                        key={idx}
                                        className="benefits d-flex align-items-center"
                                      >
                                        <SVGIcon
                                          name="greenTick"
                                          className="tick-logo"
                                        />
                                        <div className="p14">
                                          <p className="name m-0 fw-600">
                                            {el}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div
                                    className={`sign-api-error ${
                                      displayError
                                        ? "d-flex align-items-center"
                                        : "dspnone"
                                    }`}
                                  >
                                    <img
                                      src={ErrorIcon}
                                      height="10"
                                      width="10"
                                      className="signup-error-icon"
                                      alt="img"
                                    />
                                    <span className="p14">
                                      <p>{errorText}</p>
                                    </span>
                                  </div>
                                  <SignupForm
                                    formAPI={
                                      process.env
                                        .HUBSPOT_BETA_SIGNUP_API_ENDPOINT
                                    }
                                    serverCountry={serverCountry}
                                    setServerCountry={setServerCountry}
                                    DisplayErrorSetter={setDisplayError}
                                    ErrorTextSetter={setErrorText}
                                    disallowCommonDomains
                                    signupEU
                                  />
                                  <span className="action">
                                    <center>
                                      <p className="data-loc mt16">
                                        Your data will be located in the{" "}
                                        <span
                                          ref={wrapperRef}
                                          className="fw-bold text-deep-purple position-relative country-name"
                                          onClick={() =>
                                            setCountryPopup(!countryPopup)
                                          }
                                        >
                                          <div
                                            className={`position-absolute pop-up-location br-10 z-1 ${
                                              countryPopup ? "" : "dspnone"
                                            }`}
                                          >
                                            {Object.values(serverRegions).map(
                                              (item, id) => (
                                                <p
                                                  key={id}
                                                  className={`text fw-bold p14 ${
                                                    (!serverRegions[
                                                      serverCountry
                                                    ] &&
                                                      item.code === "US") ||
                                                    item.code === serverCountry
                                                      ? "text-pink"
                                                      : ""
                                                  }`}
                                                  onClick={() =>
                                                    setServerCountry(item.code)
                                                  }
                                                >
                                                  {item.country}
                                                </p>
                                              )
                                            )}
                                            <div className="position-relative">
                                              <SVGIcon
                                                name="polygon"
                                                className="polygon position-absolute"
                                              />
                                            </div>
                                          </div>
                                          {serverRegions[serverCountry]
                                            ?.country ||
                                            serverRegions["US"].country}
                                        </span>
                                      </p>
                                    </center>
                                  </span>
                                </Col>
                              )}
                            </Row>
                          </Container>
                        </section>
                      )
                    }
                    case "SuperOps_Block": {
                      return (
                        <section className="page-blk">
                          {field.blockHeading ? (
                            <h1 className="font-roboto mb30 text-center">
                              {field.blockHeading}
                            </h1>
                          ) : null}
                          <div
                            className={`${
                              field.blockTextPosition === "Right"
                                ? "text-right"
                                : "text-left"
                            }`}
                          >
                            <Block
                              html={field.blockContent?.html}
                              heading={field.blockHeading}
                              description={field.blockDescription}
                              imageTitle={field.blockImageTitle}
                              image={field.blockImage}
                              isImage={field.blockIsIllustration}
                              isVideo={field.blockIsVideo}
                              lgLeft={5}
                              lgRight={6}
                            />
                          </div>
                        </section>
                      )
                    }

                    case "SuperOps_FaqComponent": {
                      return <VerticalTabs data={field} />
                    }
                    case "SuperOps_Testimonial": {
                      if (field.testimonialType === "multiple_1") {
                        return (
                          <section className="multi-testimonal">
                            <Container className="Layout-container d-flex flex-wrap mt100 row mx-auto">
                              <Col lg={5} className="testimonal-l  ">
                                <h2 className="testimonal-heading ">
                                  {field.testimonialSectionHeading}
                                </h2>
                                <div className="testimonial-mark mt46">
                                  {field.testimonialName.map((name, index) => (
                                    <div
                                      key={index}
                                      className="d-flex  align-items-center"
                                    >
                                      <p className="percent-mark">{name}</p>
                                      <p className="description-mar">
                                        {field.testimonialDescription[index]}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </Col>
                              <Col lg={7} className="testimonal-r">
                                <Swiper
                                  loop={true}
                                  className="mySwiper"
                                  speed={750}
                                  spaceBetween={30}
                                  autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                  }}
                                  loopAdditionalSlides={1}
                                  loopFillGroupWithBlank={false}
                                  onSlideChange={swiper =>
                                    setCurrentIndex(swiper.realIndex + 1)
                                  }
                                  ref={swiperRef}
                                >
                                  {field.testimonialDatas.map((item, i) => (
                                    <SwiperSlide key={i}>
                                      <div className="swip-card d-flex flex-column">
                                        <div className="icon">
                                          <img
                                            src="https://media.graphassets.com/HxDfSXBHTxqiQlEZFnF5"
                                            alt="comma"
                                            className="w-100"
                                          />
                                        </div>

                                        <p className="content font-roboto fw-700">
                                          {item.content}
                                        </p>
                                        <div className="name-title-image d-flex">
                                          <div className="name-image">
                                            <img
                                              src={item.icon.url}
                                              alt={item.name}
                                              className="w-100"
                                            />
                                          </div>
                                          <div className="name-title">
                                            <h4 className="name  font-roboto fw-800">
                                              {item.name}
                                            </h4>
                                            <p className="title p14">
                                              {item.title}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                                <div className="swiper-status d-flex flex-wrap">
                                  <p className="status p16">
                                    {currentIndex} / {totalSlides}
                                  </p>
                                  <div className="full-progress-bar d-flex">
                                    {[...Array(totalSlides)].map((_, index) => (
                                      <div
                                        key={index}
                                        className={`progress-segment ${index}`}
                                        onClick={() => goToSlide(index + 1)}
                                        style={{
                                          backgroundColor:
                                            currentIndex === index + 1
                                              ? "#F05"
                                              : "#FFBFD4",
                                        }}
                                      ></div>
                                    ))}
                                  </div>
                                </div>
                              </Col>
                            </Container>
                          </section>
                        )
                      } else
                        return (
                          <section className="single-testimonial mb100 mt48">
                            {field.testimonialSectionHeading ? (
                              <Container className="Layout-container">
                                <h2 className="font-roboto mx-auto main-heading mb80 text-center fw-bold">
                                  {field.testimonialSectionHeading}
                                </h2>
                              </Container>
                            ) : null}

                            <Slide delay="400">
                              <TestimonialV6
                                testimonial={field.testimonialDatas}
                              />
                            </Slide>
                          </section>
                        )
                    }
                    case "SuperOps_Card": {
                      if (field.cardType === "Features") {
                        return (
                          <section className="legacy-sps">
                            <Container className="big-container">
                              <Fade bottom duration={700}>
                                <h2 className="heading font-roboto fw-800 mx-auto text-center mb30">
                                  {field.cardName}
                                </h2>
                              </Fade>

                              <Fade bottom duration={700}>
                                <p className="description mx-auto text-center mb30 p16">
                                  {field.cardDescription}
                                </p>
                              </Fade>

                              <Fade bottom duration={700}>
                                <div className="comparison-box d-flex mx-auto br-10 flex-wrap">
                                  {field.cardHeading.map((title, idx) => {
                                    return (
                                      <div
                                        className={`${
                                          idx === 0 ? "left" : "right"
                                        } box d-flex flex-column align-items-center`}
                                      >
                                        <p className="title text-center w-100 fw-800 m-0">
                                          {title}
                                        </p>
                                        <div className="lists d-flex flex-column">
                                          {field.text.map((el, ind) => {
                                            return (
                                              <div className="d-flex gap-8 align-items-center">
                                                {idx === 0 ? (
                                                  <SVGIcon name="crossRedLight" />
                                                ) : (
                                                  <SVGIcon name="pricing/pricing-yes" />
                                                )}
                                                <p className="point p18 m-0">
                                                  {idx === 0
                                                    ? el
                                                    : field.subtext[ind]}
                                                </p>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </Fade>
                            </Container>
                          </section>
                        )
                      } else if (field.cardType === "Plain_cards") {
                        return (
                          <section className="plain-card">
                            <div className="layout-wrapper mx-auto">
                              <Container className="Layout-container">
                                <Row className="align-items-center justify-content-between">
                                  <Col lg={5} className="altv3">
                                    <Fade bottom duration={700}>
                                      <h2 className="fw-bold font-roboto mb16">
                                        {field.cardName}
                                      </h2>
                                    </Fade>

                                    <Fade bottom duration={700}>
                                      <p className="p16 description">
                                        {field.cardDescription}
                                      </p>
                                    </Fade>
                                  </Col>
                                  <Col lg={6}>
                                    <Fade bottom duration={700}>
                                      <img
                                        src={field.image[0].url}
                                        alt="image"
                                        className="w-100"
                                      />
                                    </Fade>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          </section>
                        )
                      } else if (field.cardType === "Simple_cards") {
                        return (
                          <section className="simple-cards">
                            <Fade bottom duration={700}>
                              <Container className="Layout-container">
                                <h2 className="font-roboto text-center fw-bold heading mx-auto mb50">
                                  {field.cardName}
                                </h2>
                                <div className="d-flex flex-wrap justify-content-between row-gap-24">
                                  {field.text.map((item, ind) => {
                                    return (
                                      <div className="card-cover br10">
                                        <div
                                          className="img-wrapper"
                                          style={{
                                            background: `${field.title[ind]}`,
                                          }}
                                        >
                                          <img
                                            src={field.image[ind].url}
                                            alt="icons"
                                            className="w-100"
                                          />
                                        </div>
                                        <p className="p24 font-roboto fw-bold mb16">
                                          {item}
                                        </p>
                                        <p className="p16 m-0 description">
                                          {field.subtext[ind]}
                                        </p>
                                      </div>
                                    )
                                  })}
                                </div>
                              </Container>
                            </Fade>
                          </section>
                        )
                      } else if (field.cardType === "Plain_info") {
                        return (
                          <section className="plain-info">
                            <Container className="Layout-container">
                              <Fade bottom duration={700}>
                                <Row className="row-gap-24">
                                  <Col lg={4}>
                                    <h2 className="font-roboto fw-bold">
                                      {field.cardName}
                                    </h2>
                                  </Col>
                                  <Col lg={8} className="d-flex flex-wrap">
                                    <Row className="gap-row mt10">
                                      {field.text.map((item, idx) => {
                                        return (
                                          <Col lg={6}>
                                            <div className="d-flex gap-8 mb16 align-items-center">
                                              <div className="icon-wraper">
                                                <img
                                                  src={field.image[idx].url}
                                                  alt="icon"
                                                />
                                              </div>
                                              <p className="p18 fw-bold font-roboto m-0">
                                                {item}
                                              </p>
                                            </div>
                                            <p className="p16 subtext">
                                              {field.subtext[idx]}
                                            </p>
                                          </Col>
                                        )
                                      })}
                                    </Row>
                                  </Col>
                                </Row>
                              </Fade>
                            </Container>
                          </section>
                        )
                      } else if (field.cardType === "Double_cards") {
                        return (
                          <ImagePointer
                            data={field}
                            version="version-v2"
                            trigger="auto"
                          />
                        )
                      } else if (field.cardType === "storyCards") {
                        return (
                          <section className="story-section ">
                            <Container className="Layout-container d-flex flex-wrap ">
                              <Col lg={6} className="story-l">
                                <div className="story-img">
                                  <img
                                    className="w-100"
                                    src={field.image[0].url}
                                    alt="story-img"
                                  />
                                </div>
                              </Col>
                              <Col lg={4} className="story-r">
                                <h2 className="story-heading">
                                  {field.cardName}
                                </h2>
                                <p className="story-desc p16">
                                  {field.cardDescription}
                                </p>
                                <Buttons
                                  theme="primary-new"
                                  text={field.text[0]}
                                  link={field.subtext[0]}
                                />
                              </Col>
                            </Container>
                          </section>
                        )
                      } else if (field.cardType === "slideCards") {
                        return (
                          <MarqueeImages
                            heading={field.title}
                            images={field.image}
                            newOpacity
                          />
                        )
                      } else
                        return (
                          <section className="benefit-cards mb100">
                            <Fade bottom duration={700}>
                              <div className="layout-wrapper mx-auto">
                                <Container className="Layout-container d-flex justify-content-between wrapper position-relative">
                                  {field.text.map((item, ind) => {
                                    return (
                                      <Col
                                        lg={4}
                                        className="ben-card text-center br10"
                                        style={{ background: field.title[ind] }}
                                      >
                                        <p className="fw-bold number m-0">
                                          {item}
                                        </p>
                                        <p className="font-roboto p24 fw-bold m-0">
                                          {field.subtext[ind]}
                                        </p>
                                      </Col>
                                    )
                                  })}
                                </Container>
                                <div className="d-flex justify-content-center">
                                  <div className="img-wrap position-relative z-1">
                                    <img
                                      src={field.image[0].url}
                                      className="image"
                                      alt="logo"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Fade>
                          </section>
                        )
                    }
                    case "SuperOps_Advantage": {
                      return (
                        <section className="badge-section">
                          <Container className="Layout-container">
                            <Fade bottom duration={700}>
                              <Row>
                                <Col lg={5} className="altv1">
                                  <h4 className="font-roboto fw-800 mb30 heading mx-auto">
                                    {field?.advantagesHeading[0]}
                                  </h4>
                                </Col>
                                <Col lg={7}>
                                  <div className="d-flex justify-content-center flex-wrap badge-wrapper">
                                    {field.advantagesIcon.map(img => {
                                      return (
                                        <div className="badge-img">
                                          <img
                                            src={img.url}
                                            className="w-100"
                                          />
                                        </div>
                                      )
                                    })}
                                  </div>
                                </Col>
                              </Row>
                            </Fade>
                          </Container>
                        </section>
                      )
                    }
                    case "SuperOps_CtaFeature": {
                      return (
                        <>
                          <section className="mt100 normal-cta">
                            <CTA
                              data={[field.ctaBox]}
                              className="Layout-container"
                              lgLeft={6}
                              lgRight={3}
                              newButton
                              newDesign
                              disallowCommonDomains
                            />
                          </section>
                          {field.ctaBox.type === "PopUp" ? (
                            <Container className="Layout-container mx-auto">
                              <Fade bottom duration={700}>
                                <p className="footer-text p16 text-center m-0 pb100">
                                  Have questions? Our team is here to help.{" "}
                                  <Link to="/contact-us" className="link">
                                    Contact us today!
                                  </Link>
                                </p>
                              </Fade>
                            </Container>
                          ) : (
                            <p className="pb100 m-0"></p>
                          )}
                        </>
                      )
                    }
                    default:
                      return <></>
                  }
                })}
              </main>
            </Frame>
          </>
        </div>
      )}
    />
  )
}

export default LpPage
